import "./App.css";
import axios from "axios";
import Header from "./components/Header";
import BitcoinCard from "./components/BitcoinCard";
import { createContext, useEffect, useState } from "react";
const Appstate = createContext();
function App() {
  const [apidata, setapidata] = useState([]);
  const [searchdata, setsearchdata] = useState("");
  useEffect(() => {
    axios
      .get("https://api.coinstats.app/public/v1/coins?skip=0&")
      .then((respose) => {
        setapidata(respose.data.coins);
      });
  }, [searchdata]);

  const filterdata = apidata.filter((datas) => {
    return datas.name.toLowerCase().includes(searchdata.toLowerCase());
  });

  return (
    <Appstate.Provider value={{ filterdata, searchdata, setsearchdata }}>
      <div className="App">
        <Header />
        <BitcoinCard />
      </div>
    </Appstate.Provider>
  );
}

export default App;
export { Appstate };
