import React, { useContext, useState } from "react";
import "./Header.css";
import { Appstate } from "../App";

const Header = () => {
  const [ishover, setishover] = useState(false);
  const [isfocus, setisfocus] = useState(false);
  const inputClass = ishover ? "issearch display-search" : "issearch";

  const { setsearchdata } = useContext(Appstate);
  const handlefocus = () => {
    setisfocus(true);
    setishover(true);
  };
  // const [getdata, setgetdata] = useState("");
  return (
    <div className="header">
      <div>
        <img
          src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/46/Bitcoin.svg/1200px-Bitcoin.svg.png"
          alt="Crypto logo"
        />
      </div>
      <div
        onMouseLeave={() =>
          isfocus === false ? setishover(false) : console.log("do nothing")
        }
      >
        <input
          className={inputClass}
          placeholder="Search"
          onChange={(e) => setsearchdata(e.target.value)}
          onFocus={handlefocus}
          onBlur={() => {
            setisfocus(false);
            setishover(false);
          }}
        ></input>
        {/* <span>{searchdata}</span> */}
        <i
          className={`fa-sharp fa-solid fa-magnifying-glass ishover`}
          onMouseEnter={() => {
            setishover(true);
          }}
        ></i>
      </div>
    </div>
  );
};

export default Header;
