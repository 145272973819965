import React, { useContext } from "react";
import { Appstate } from "../App";
import "./BitcoinCard.css";
const BitcoinCard = () => {
  const apiData  = useContext(Appstate).filterdata;
  return (
    <div className="home">
      <span>Crypto Coins</span>
      {apiData.map((coin, index) => {
        return (
          <div className="card-container" key={index}>
            <span>Name : {coin.name}</span>
            <img src={coin.icon} alt={coin.id} />
            <span>Price : {coin.price}</span>
            <span>Symbol : {coin.symbol}</span>
          </div>
        );
      })}
    </div>
  );
};

export default BitcoinCard;
